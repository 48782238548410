<script>
  import Sidebar from './components/Sidebar.svelte';
  import Content from './components/Content.svelte';

  export let config;

  let envId = 0;
  $: env = config.environments[envId];

  const jsonUrl = window.location.origin + window.INSOMNIA_URL;
  const runInInsomniaLink = `https://insomnia.rest/run/?label=${encodeURIComponent(config.workspace.name)}&uri=${encodeURIComponent(jsonUrl)}`;

  let menuVisible = false;

  function toggleHamburger() {
    menuVisible = !menuVisible;
  }
</script>

<svelte:head>
  <title>{config.workspace.name}</title>
</svelte:head>

<header>
  <div class="header-left">
    <a href="javascript:;" class="hamburger-toggler" on:click='{toggleHamburger}'>
      <i class="fa fa-bars"></i>
    </a>

    <div class="logo">
      <img src="logo.png" alt={config.workspace.name} />
    </div>

    <h1 class="title">{config.workspace.name}</h1>
  </div>
  <div class="header-right">
    <div class="run">
      <a href={runInInsomniaLink} target="_blank">
        <img src="https://insomnia.rest/images/run.svg" alt="Run in Insomnia" />
      </a>
    </div>
    <div class="environment">
      <span>Environment:</span>
      <select bind:value={envId}>
        {#each config.environments as environment, idx}
          <option value={idx}>{environment.name}</option>
        {/each}
      </select>
    </div>
  </div>
</header>

<section class="wrapper">
  <Sidebar config={config} visible={menuVisible} />
  <Content
    requests={config.requests}
    groups={config.groups}
    workspace={config.workspace}
    cookiejars={config.cookiejars}
    {env}
  />
</section>

<style type="scss" global>/*
Monokai style - ported by Luigi Maselli - http://grigio.org
*/
:global(.hljs) {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #272822;
  color: #ddd; }

:global(.hljs-tag),
:global(.hljs-keyword),
:global(.hljs-selector-tag),
:global(.hljs-literal),
:global(.hljs-strong),
:global(.hljs-name) {
  color: #f92672; }

:global(.hljs-code) {
  color: #66d9ef; }

:global(.hljs-class .hljs-title) {
  color: white; }

:global(.hljs-attribute),
:global(.hljs-symbol),
:global(.hljs-regexp),
:global(.hljs-link) {
  color: #bf79db; }

:global(.hljs-string),
:global(.hljs-bullet),
:global(.hljs-subst),
:global(.hljs-title),
:global(.hljs-section),
:global(.hljs-emphasis),
:global(.hljs-type),
:global(.hljs-built_in),
:global(.hljs-builtin-name),
:global(.hljs-selector-attr),
:global(.hljs-selector-pseudo),
:global(.hljs-addition),
:global(.hljs-variable),
:global(.hljs-template-tag),
:global(.hljs-template-variable) {
  color: #a6e22e; }

:global(.hljs-comment),
:global(.hljs-quote),
:global(.hljs-deletion),
:global(.hljs-meta) {
  color: #75715e; }

:global(.hljs-keyword),
:global(.hljs-selector-tag),
:global(.hljs-literal),
:global(.hljs-doctag),
:global(.hljs-title),
:global(.hljs-section),
:global(.hljs-type),
:global(.hljs-selector-id) {
  font-weight: bold; }

:global(html), :global(body) {
  position: relative;
  width: 100%; }

:global(body) {
  color: #333;
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }

:global(h1) {
  font-size: 36px; }

:global(h1), :global(h2), :global(h3), :global(h4) {
  font-weight: normal;
  margin: 0;
  padding: 10px 0; }

:global(hr) {
  border: none;
  display: block;
  height: 1px;
  background: #e7e7e7; }

:global(a) {
  color: #0064c8;
  text-decoration: none; }
  :global(a:hover) {
    text-decoration: underline; }

:global(label) {
  display: block; }

:global(input), :global(button), :global(select), :global(textarea) {
  font-family: inherit;
  font-size: inherit;
  padding: 0.4em;
  margin: 0 0 0.5em 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 2px; }

:global(input:disabled) {
  color: #ccc; }

:global(input[type="range"]) {
  height: 0; }

:global(button) {
  color: #333;
  background-color: #f4f4f4;
  outline: none; }
  :global(button:active) {
    background-color: #ddd; }
  :global(button:focus) {
    background-color: #666; }

:global(.row) {
  display: flex;
  height: 100%; }

:global(.left), :global(.right) {
  box-sizing: border-box;
  padding: 10px 15px;
  flex: 0 0 50%;
  min-width: 50%; }

:global(.right) {
  background: #232323;
  color: #fff; }

:global(.sidebar-list-link) {
  display: block;
  padding: 5px 15px;
  margin-bottom: 5px;
  text-decoration: none;
  color: #222;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }
  :global(.sidebar-list-link:hover) {
    background: rgba(0, 0, 0, 0.05);
    text-decoration: none; }
  :global(.sidebar-list-link.expanded) {
    background: rgba(0, 0, 0, 0.1); }
  :global(.sidebar-list-link::before), :global(.sidebar-list-link span), :global(.sidebar-list-link strong) {
    display: inline-block;
    vertical-align: middle; }
  :global(.sidebar-list-link::before) {
    margin-right: 7px; }
  :global(.sidebar-list-link strong) {
    font-size: 11px;
    margin-right: 5px; }
    :global(.sidebar-list-link strong.get) {
      color: #7d69cb; }
    :global(.sidebar-list-link strong.post) {
      color: #59a210; }
    :global(.sidebar-list-link strong.put) {
      color: #ff9a1f; }
    :global(.sidebar-list-link strong.patch) {
      color: #d07502; }
    :global(.sidebar-list-link strong.delete) {
      color: #d04444; }
    :global(.sidebar-list-link strong.options), :global(.sidebar-list-link strong.head) {
      color: #1c90b4; }

:global(.request-title) {
  font-weight: 600; }
  :global(.request-title strong) {
    display: inline-block;
    padding: 5px 8px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 3px;
    margin-right: 10px;
    font-size: 17px;
    background: #1c90b4; }
    :global(.request-title strong.get) {
      background: #7d69cb; }
    :global(.request-title strong.post) {
      background: #59a210; }
    :global(.request-title strong.put) {
      background: #ff9a1f; }
    :global(.request-title strong.patch) {
      background: #d07502; }
    :global(.request-title strong.delete) {
      background: #d04444; }

:global(.hljs) {
  padding: 0;
  background: transparent; }

:global(.description code), :global(.description pre) {
  background: #eee;
  text-shadow: 0 1px #fff;
  padding: 0 .3em;
  white-space: pre-wrap;
  overflow-wrap: break-word; }

:global(.description pre) {
  padding: 10px 15px; }
  :global(.description pre code) {
    padding: 0; }

:global(.language-selector .selectContainer) {
  transition: background 150ms linear; }
  :global(.language-selector .selectContainer:hover) {
    background: #454545; }
  :global(.language-selector .selectContainer *) {
    cursor: pointer !important; }

:global(.table pre) {
  margin: 0;
  background: #efefef;
  padding: 10px;
  white-space: pre-wrap;
  overflow-wrap: break-word; }

:global(.tables .table:last-child) {
  border-bottom: none !important; }

:global(.hamburger-toggler) {
  display: none; }

:global(table) {
  background: #fff;
  border: solid 1px #ddd;
  margin-bottom: 1.25rem;
  width: 100%;
  border-collapse: collapse; }
  :global(table thead),
  :global(table tfoot) {
    background: #f5f5f5; }
  :global(table th),
  :global(table td) {
    color: #222222;
    padding: 0.5rem; }
  :global(table tr:nth-of-type(even)) {
    background: #F9F9F9; }

@media only screen and (max-width: 1000px) {
  :global(aside) {
    display: none; }
  :global(.content) {
    margin-left: 0 !important; }
  :global(.row) {
    display: block; }
  :global(.left), :global(.right) {
    width: auto; }
  :global(.right) {
    padding: 0; }
  :global(.language-selector) {
    padding: 10px; }
  :global(.language-selector select) {
    margin: 0; }
  :global(header .environment span) {
    display: none; }
  :global(header .title) {
    font-size: 18px !important; }
  :global(header .logo) {
    margin-left: 0 !important;
    padding: 0 !important; }
  :global(header .environment) {
    padding: 0 5px !important; }
  :global(header .run) {
    display: none !important; }
  :global(.hamburger-toggler) {
    display: inline-block;
    padding: 15px; } }

:global(header) {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #dedede;
  background: #fff;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  height: 60px;
  overflow: hidden; }

:global(header .header-left), :global(header .header-right) {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

:global(header .title) {
  padding: 0 10px;
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle; }

:global(header .hamburger-toggler) {
  vertical-align: middle;
  font-size: 22px;
  color: #000; }

:global(header .logo) {
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
  margin-left: 30px;
  width: 48px;
  height: 48px; }

:global(header .logo img) {
  width: 100%;
  height: 100%; }

:global(header .environment) {
  font-size: 13px;
  padding: 0 30px;
  display: inline-block;
  vertical-align: middle; }

:global(header .environment select) {
  margin-bottom: 0; }

:global(header .run) {
  display: inline-block;
  vertical-align: middle; }

:global(.wrapper) {
  margin-top: 60px; }</style>
